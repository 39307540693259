:root, [data-theme] {
    --www-demoablediv-font-family: var(--cds-font-body-family);
    --www-demoablediv-line-height: var(--cds-font-body-line-height-medium);
    --www-demoablediv-font-size: var(--cds-font-body-size-medium);
    --www-demoablediv-color-border: var(--cds-border-color);
    --www-demoablediv-color-background-1: var(--cds-background-color);
    --www-demoablediv-color-background-2: var(--cds-background-color-inverted-light-opaque);
    --www-demoablediv-color-text: var(--cds-text-color);
}

.www-demoablediv {
    margin-top: var(--cds-size-4);
    margin-bottom: var(--cds-size-4);
}

.www-demoablediv .area {
    font-family: var(--www-demoablediv-font-family);
    line-height: var(--www-demoablediv-line-height);
    font-size: var(--www-demoablediv-font-size);
    color: var(--www-demoablediv-color-text);
    border: 1px solid var(--www-demoablediv-color-border);

}
.www-demoablediv .area--transparent {
    background: repeating-conic-gradient(
            var(--www-demoablediv-color-background-2) 0 90deg,
            var(--www-demoablediv-color-background-1) 0 180deg) 0 0/40px 40px;
}

.www-demoablediv .area--opaque {
    background: var(--cds-background-color);
}

